import react from 'react'
import { useState, useEffect } from "react";
import axios from 'axios';
function Imanny(){
    const [posts,setPosts] = useState([])

    useEffect(()=>{
        const url = '/api/iManny10.json'
       
        axios.get(url)
          .then(res=>{
            setPosts(res.data)
            console.log(posts)
         })
            .catch(err =>{ 
                 
                console.log("voici error =>>>>>>",err)
            }) 
 

    },[])
/* 1->{
                posts.map((post,index)=><li key={index}>{post.toolName} 
                                                {post.contacts.length>0? post.contacts.map((post2,index2)=><li key={index2}>{post2.email}</li>) : null}</li>
                                             )
              }*/
              /*2->{
                posts.map((post,index)=><li key={index}>{post.toolName} 
                                                {post.contacts.contactPerson? post.contacts.contactPerson : null} </li>
                                             )
              }*/
    return(
        <div>
            <ul>
            {
                posts.map((post,index)=><li key={index}>{post.toolName} 
                                                {post.contacts.length>1 ? post.contacts.map((post,index)=><div key={index}>{post.email.length==null? 'aucun' :post.email}</div>) : "SUP A 2"}</li>
                                             )
              }
            </ul>
        </div>
    )
 
}

export default Imanny