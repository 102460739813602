import React from "react";
import '../styles/NavBottom.css'
import { BottomNavigation,BottomNavigationAction } from "@mui/material";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
 
 import { useState } from "react";
 
function NavBottom(){
    const [value,setValue]=useState([]);
    return(
        <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction label="Récents" icon={<RestoreIcon />} />
      <BottomNavigationAction label="Favoris" icon={<FavoriteIcon />} />
      <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
    </BottomNavigation>
    )


}

export default NavBottom