import React from 'react';
 
 
function Expenses(){
 
    return(
        <main style={{padding: "1em 0"}} className="expenses">  
            <h2>Bienvenue sur Expenses</h2>
        </main>
    )
}

export default Expenses

