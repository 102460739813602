
import React, { Component }  from 'react';
import '../styles/tweet.css'

import moment from 'moment'
 
function Tweet({...x}){
 
const now = moment().format('d.m.y')

return((<div className="tweet" >
                                    <div className='tweethead'>
                                        <span className='tweetusername'>
                                        {x.username}</span>
                                        <span className='tweetdate'>{x.date ? x.date : `${now} ` }</span>
                                    </div>
                                   <div className='contenu'>{x.content}</div>
                                </div>
                                )
    )
}
 
export default Tweet