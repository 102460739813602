import React, { Component }  from 'react';
import axios from "axios";
import { useState } from "react";
function PostMe({donnees,setDonnees,initialDatas}){
    const [back,setBack] = useState('Resultat de post');
    const formElt = document.getElementById('inscriptionForm');
    const buttonsArray=
         [
            {label:'',
            type:'submit',
            tag:'button',
            name:'S\'inscrire',
            id:'submit',
            placeholder:'envoyer',
            value:''}]

        function postForm(e){
            e.preventDefault();
            const url='/post/traitement.php'
          
                axios.post(url,donnees)
                .then(res=>{
                    console.log(donnees);
                    setBack(res.data);
                }).catch(err =>{
                    console.log(err)
                }).finally(()=>{
                   console.log("Finally")
                    
                }
                )
  
    }
    return(
        <div>
        <button id="submit" type="submit" onClick={(e)=>postForm(e)}>S'inscrire</button>
        <div id="resultatPost">{back}</div>
        </div>
    )
}

export default PostMe