import React from 'react'
import Tweet from './Tweet' 

function Mur({tweets}){
 
 

    return(
        <div className='tweetlist'>
               {
                tweets.map((tweet,index)=>
                <Tweet
                 key={index} 
                 index={index}
                  {...tweet} />
                )
               }
              
          </div>
        
    )
}

export default Mur