import React from 'react';
function Titre({Htitre,HdefinirTitre}){ 
    return(
        <div className="h1">
           <h1>{Htitre}</h1>
           <input
           type="text"
            value={Htitre}
            onChange={(e) => HdefinirTitre(e.target.value)}
            placeholder='Titre'
             />
             
        </div>
    )
} 
export default Titre
