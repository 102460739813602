import React from 'react';
import '../styles/Tennis.css'
 
import { useState } from 'react'

function Tennis(){
        const initialState={p1:0,
            p2:0,
            winner:null,
            disabled:false,
            etat:'choisir un joueur'};

       const [playerScore,setPlayerScore] = useState(initialState)

    function pause(){
            setPlayerScore({...playerScore,etat:'pause'});
            if(playerScore.etat=='pause'){
                setPlayerScore({...playerScore,etat:'en cours'})
            }
            return playerScore
    }

    function reset(){
        setPlayerScore(initialState);
        return playerScore
    }

    function pointJoueur1(){
       if(playerScore.etat=="pause"){
        return playerScore
       }

        if(playerScore.p1<30){
           setPlayerScore( playerScore => ({...playerScore,p1:playerScore.p1+15,etat:'en cours'}))
           return playerScore
        }
        if(playerScore.p1>=30 && playerScore.p1<40 ){
            setPlayerScore( playerScore => ({...playerScore,p1:playerScore.p1+10,}))
            return playerScore
       
        }
        if(playerScore.p1===40){
            {
                     
                    if(playerScore.winner===1){
                        console.log(playerScore)
                        setPlayerScore(playerScore =>({...playerScore,etat:'Le Joueur 1 a gagné', winner:1, disabled:true}))
                        return playerScore
                    }

                    if(playerScore.p1 === playerScore.p2 ){
                        setPlayerScore( playerScore => ({...playerScore,etat:'Avantage joueur 1', winner:1}))
                        return playerScore
                    }
                    setPlayerScore(playerScore =>({...playerScore,etat:'Le Joueur 1 a gagné', winner:1, disabled:true}))
            }
            }
 }


    function pointJoueur2(){
           if(playerScore.etat=="pause"){
            return playerScore
        }
        if(playerScore.p2<30){
           setPlayerScore( playerScore => ({...playerScore,p2:playerScore.p2+15,etat:'en cours'}))
           return playerScore
        }
        if(playerScore.p2>=30 && playerScore.p2<40){
            setPlayerScore( playerScore => ({...playerScore,p2:playerScore.p2+10}))
            return playerScore
         }
         if(playerScore.p2===40){
            {
                     
                    if(playerScore.winner===2){
                        console.log(playerScore)
                        setPlayerScore(playerScore =>({...playerScore,etat:'Le Joueur 2 a gagné', winner:2, disabled:true}))
                        return playerScore
                    }

                    if(playerScore.p2 === playerScore.p1 ){
                        setPlayerScore( playerScore => ({...playerScore,etat:'Avantage joueur 2', winner:2}))
                        return playerScore
                    }
                    setPlayerScore(playerScore =>({...playerScore,etat:'Le Joueur 2 a gagné', winner:2, disabled:true}))
            }
            }
    }
    return(
        <main>
              
        <div className="tennis">
        <h1>Tennis Rounds</h1>
            <div className="score">
                Le score est : {playerScore.p1} - {playerScore.p2} <br/>
                {playerScore.etat}
            </div>
            <div className="groupeBoutons">
                <button className="pad" id="joueur1" onClick={pointJoueur1} disabled={playerScore.disabled}>Point Joueur 1</button>
                <button className="pad" id="joueur2" onClick={pointJoueur2} disabled={playerScore.disabled}>Point Joueur 2</button>
                <button className="pad" id="reset" onClick={reset}>Reset</button>
                <button className="pad" id="pause" onClick={pause}  disabled={playerScore.disabled}>Pause</button>
            </div>
        </div>
        </main>
    )
}

export default Tennis