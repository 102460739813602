
import '../styles/App.css'
import * as React from "react";
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { Container } from '@mui/system';
import Tweetbox from './Tweetbox';
import Mur from './Mur';
import tweets from '../datas/tweets';
import Nav from './Nav';
import MonComposant from './MonComposant';
import Expenses from '../routes/Expenses';
import Invoices from '../routes/Invoices';
import Invoice from '../routes/Invoice';
import Thermo from '../routes/Thermo';
import Tennis from '../routes/Tennis';
import Inscription from './Inscription';
import NavBottom from './NavBottom';
import Imanny from './Imanny';
function App() {
 
const [titre, setTitre] = useState('JUPIXEL APP')
const [message, setMessage] = useState('')
const [allTweets,setTweets]=useState([...tweets])
const [color,setColor] = useState('bleue')
const [count,setCount]= useState(0)
const [number,setNumber] = useState(0)
const [disabled,setDisabled] = useState(true)


  return(
    <BrowserRouter>
    <div className="App">
    <Container maxWidth="xl" >
        <Nav couleur={color} titre={titre} definirTitre={setTitre} />
        <Routes>
                <Route path="/" />
                <Route path="inscription" element={<Inscription />}  /> 
                <Route path='tweetbox' element={<main><Tweetbox message={message} definirMessage={setMessage} setTweets={setTweets} tweets={allTweets} /><Mur tweets={allTweets} /></main>} />
                <Route path="moncomposant" element={<MonComposant nombre={count} fonction={setCount} />} />
                <Route path="expenses" element={<Expenses />} />
                <Route path="imanny" element={<Imanny />} />
                <Route path="invoices" element={<Invoices />}>
                  <Route path=":invoiceId" element={<Invoice />} />
                </Route>
                <Route path="thermo" element={<Thermo degres={number} definirDegres={setNumber} couleur={color} definirCouleur={setColor} activation={disabled} definirActivation={setDisabled} />} />
                <Route path="*" element={ <main style={{ padding: "1rem" }}> <p>There's nothing here!</p> </main> }  />
      <Route path="tennis" element={<Tennis />} />
      </Routes> 
    </Container> 
    <NavBottom />
    </div>
</BrowserRouter>
  )
}

export default App
 