import { useEffect, useState } from 'react'
import React from 'react';
import '../styles/Thermo.css'

function Thermo({degres,definirDegres, couleur, definirCouleur,activation,definirActivation}){


    function upNumber(){
       definirDegres(degres+1)
    }
    

    function downNumber(){
        
        definirDegres(degres-1)   
        
                if(degres==0){
                   definirDegres(degres)
                }
    }
   
    useEffect(
        ()=>( 
         degres<10 ?
            definirCouleur('bleue') :
       
            definirCouleur('rouge') , 
        degres > 19  ?
            definirCouleur('rose') :    ''  ,
        
        degres < 1 ? 
        definirActivation(true) : definirActivation(false))
    )
    return(
    <main>   
    
        <h1>Le thermometre</h1>
        <div className="buttons">
        <button onClick={downNumber} disabled={activation} >-</button>
        <span className={`yoyo ${couleur}`}>{degres}{couleur}</span>
        <button onClick={upNumber}>+</button>
        <script  type="text/javascript" >alert('Hello')</script>
        </div>
        
    </main>
       
    )
}

export default Thermo