import * as React from 'react';
 
import {Outlet, Link} from "react-router-dom";
import '../styles/Nav.css';
import Titre from "./Titre";
import { useState } from "react";
import { LinearProgress,CircularProgress, Button } from '@mui/material';

function Nav({couleur,titre, definirTitre}){
   
    
    return(
       
        <nav className={`nav${couleur}`} > 
              <Titre Htitre={titre} HdefinirTitre={definirTitre}  />
              
                 <Link to="/moncomposant"> <Button variant="contained">monComposant</Button></Link>| {" "}
                  <Link to="/inscription"><Button variant="contained">Inscription</Button></Link>| {" "}
                 <Link to="/tweetbox"> <Button variant="contained">Messages</Button></Link>| {" "}
                 <Link to="/invoices"> <Button variant="contained">Invoices</Button> </Link> | {" "}
                  <Link to="/expenses"><Button variant="contained">Expenses </Button>  </Link>| {" "}
                  <Link to="/thermo"><Button variant="contained">Le Thermo </Button></Link>| {" "}
                  <Link to="/tennis"><Button variant="contained">Tennis</Button></Link> | {" "}
                  <Link to="/imanny"><Button variant="contained">imanny</Button></Link> | {" "}
                  <Link to="/"><Button variant="contained">App</Button></Link>
                  <Outlet/>
             
         </nav>
       
    )
     
  
}
export default Nav
 