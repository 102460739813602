import React  from 'react';
import { Link, Outlet } from "react-router-dom";

import invoices from "../datas/invoices";
import { useState } from "react";
 
export default function Invoices() {
   const [AllInvoices,setInvoices] = useState([...invoices])
   
  return (
    
    <div style={{ display: "flex" }}>
       
          <main
            style={{
              borderRight: "solid 1px",
              padding: "1rem",
            }}
          >
            {AllInvoices.map((invoice) => (
              <Link
                style={{ display: "block", margin: "1rem 0" }}
                to={`/invoices/${invoice.number}`}
                key={invoice.number}
              >
                {invoice.name}
              </Link>
              
            ))}
           <Outlet/>
          </main>
    </div>
  );
}