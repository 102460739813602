
import React from 'react';
import { Button } from '@mui/material';
function MonComposant({nombre,fonction}){


 
 return(
    <div>
        <Button variant="contained" onClick={()=>fonction(nombre+1)}> {nombre} </Button>
    </div>
 )
}

export default MonComposant