import React from 'react';
import { useEffect, useState } from "react"
import axios from "axios"

function DataFetching(){
    const [posts,setPosts] = useState([{prenom:'INIT',nom:'ZERO',age:99}])
    const url = '/api/inscrits.json'

    function fetchData(){
          
        axios.get(url)
        .then(res=>{
            if(Array.isArray(res.data)){
                console.log('resdata is array')
                setPosts(res.data)
                 
            } else {
                console.log(res.data.length)
                console.log('resdata est inferieur a zero')
                setPosts([{prenom:'RES',nom:'notArray',age:99}])
            }
        
       })
          .catch(err =>{  
              console.log("voici error =>>>>>>",err)
              setPosts([{prenom:'CATCH',nom:'ERROR',age:99}])
          }) 


  }
    
        useEffect(()=>{
         fetchData()
         console.log(posts)
        },[])

    return(
        <div>
            <ul>
                {
                     posts!==0? posts.map(({nom,prenom,age},index) =><li key={index}>{prenom} {nom} {age} ans</li>)  : 'Pas d\inscrits'
                }
            </ul>
        </div>
    )
}

export default DataFetching 