import React, { Component }  from 'react'; 
import '../styles/Tweetbox.css'
import moment from 'moment'
import { useState } from 'react'
 
 
function Tweetbox({message,definirMessage,setTweets,tweets,coords}){
 
  const [disabled,setDisabled] = useState(true)
 
 function test(message){
  definirMessage(message)
  if(!message.length || message.length>=140){
    setDisabled(true)
    return disabled
  }
  setDisabled(false)
  return disabled
 }

 function handleSubmit(){

    setTweets([{
      avatar: 'https://pbs.twimg.com/profile_images/797746572742234112/yaTOzHW8_bigger.jpg',
      username: 'Jupixian',
      fullname: 'Jupixian',
      content: message,
      date: moment().format('DD/MM/YYYY'),
    },...tweets])
 }

    return(
        <div className="tweetbox">
         <h2>Tweetez...</h2>
           <textarea
           row={3}
           type="text"
            value={message}
            onChange={(e) => test(e.target.value)}
            placeholder='Votre Tweet'
             />
             <span className={`length ${message.length>=140 ?'red' :''}`}>{140-message.length} carac</span>
             <button disabled={disabled} onClick={handleSubmit}>Tweeter </button>
             
        </div>
    )
}


export default Tweetbox