import React from 'react';
import { useState } from "react";
import { TextField, Switch,Checkbox } from '@mui/material';
function Inputs({tagElt,typeElt,nameElt,idElt,labelElt, minElt, maxElt,coords,setCoords, helperTextElt}){
    const [saisie,setSaisie]=useState('');
    let output = '';

    let label=labelElt;
    
    
     function changeValue(saisie){
            setSaisie(saisie);
           
            if(nameElt=='nom'){
                    setCoords({...coords,nom:saisie});
                    
            }
            if(nameElt==='prenom'){
                setCoords(coords => ({...coords,prenom:saisie}));
                
                 }
            if(nameElt==='age'){
                setCoords(coords => ({...coords,age:saisie}));
                
            }
         
         return coords
     }

    
 /* output=<input type={typeElt} id={idElt} name={nameElt} value={saisie} onChange={(e,nameElt)=>changeValue(setSaisie(e.target.value),nameElt)} />;*/  
   if(tagElt==='input'){
       
            if(typeElt=='text'){
                output=<TextField  label={labelElt.length>0?labelElt:null} variant="standard"  helperText={helperTextElt} type={typeElt} id={idElt} name={nameElt} value={saisie} onChange={(e)=>changeValue(e.target.value)} />;
            }
            if(typeElt=='number'){
                output=<div><label for={nameElt}>{labelElt}</label><input type={typeElt} id={idElt} name={nameElt} min={minElt} max={maxElt} onChange={(e)=>changeValue(e.target.value)}  /></div>;
            }
            if(typeElt=='checkbox'){
                output=<div><label for={nameElt}>{labelElt}</label><Switch  {...label} type={typeElt} defaultChecked /></div> ;
            }
               
    }
    /*
   if(tagElt==='button'){ 
       output=<button type={typeElt} id={idElt} >{nameElt}</button>;
    }*/
    /*
    A VOIR le 14/11 :onChange={(e)=> definirValeur(e.target.value)}} 
     {tagElt=='input'?<input type={typeElt} name={nameElt} id={idElt} /> : <button type={typeElt} >Envoyer</button>}
    */
 
    return( 
        <div className='wrap'>
           {output}
        </div>
       
    )
}

export default Inputs