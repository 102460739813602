import React, { useEffect } from 'react';
import Inputs from './Inputs';
import PostMe from './PostMe';
import { useState } from 'react';
 
import '../styles/Inscription.css';
import DataFetching from './DataFetching';
 

function Inscription(){ 

   const inputsArray = [
        
         {
            label:'Nom',
            tag:'input',
            helperText:'Entrez votre nom s\'il vous plait ',
            type:'text',
            id:'name',
            name:'nom',
            value:'',
        },
        {
            label:'Prénom',
            tag:'input',
            helperText:'Entrez votre prénom s\'il vous plait ',
            type:'text',
            name:'prenom',
            id:'lastname',
            value:'',
        },
        {
            label:'Age',
            tag:'input',
            helperText:'Entrez votre âge s\'il vous plait',
            id:'age',
            name:'age',
            type:'number',
            value:'',
            min:'18',
            max:'100',
        },{
            label:'Avez-vous plus de 18 ans ?',
            tag:'input',
            helperText:'activez si oui, ou désactivez si non',
            type:'checkbox',
            name:'checkAge',
            id:'checkAge',
            value:'',
        }
      
    ];
    
    const datas={
        nom:'',
        prenom:'',
        age:'',
      }
      
      const [etat,setEtat] = useState(datas)
      /* {
            label:'',
            type:'submit',
            tag:'button',
            name:'S\'inscrire',
            id:'submit',
            placeholder:'envoyer',
            value:'',
        }*/
       useEffect(()=>{console.log('useEffect Inscription')},[])
    return(
        <main className="mainInscription">
            <h1>Inscription sur JPX</h1>
          
            {`Bonjour ${etat.nom} ${etat.prenom} `}{etat.age? `${etat.age} ans` : null}
            <form id='inscriptionForm' method='post' action=''>
            {inputsArray.map((x,index)=>(<Inputs key={index} index={index} tagElt={x.tag} typeElt={x.type} nameElt={x.name} idElt={x.id} labelElt={x.label} minElt={x.min} maxElt={x.max} coords={etat} setCoords={setEtat} helperTextElt={x.helperText} />))}
             </form>
             <PostMe donnees={etat} setDonnees={setEtat} initialDatas={datas}/>
             <h2>Liste des inscrits</h2>
             <DataFetching />
        </main>
    )
}

export default Inscription