const tweets = [{
    avatar: 'https://pbs.twimg.com/profile_images/553711083064541184/9VsY9i09_bigger.jpeg',
    username: 'dan_abramov',
    fullname: 'Dan Abramov',
    content: 'Now, this is a configuration that makes sense to me. Specify browsers once, let #Babel and #Autoprefixer do the work. https://github.com/facebookincubator/create-react-app/pull/1249',
    date: '12/12/2016',
  }, {
    avatar: 'https://pbs.twimg.com/profile_images/669569370134290432/04TXkEbC_bigger.png',
    username: 'elephorm',
    fullname: 'Elephorm',
    content: 'Formez-vous dès maintenant au logiciel de montage vidéo accessible à tous Premiere Elements 15 ! #adobe http://ow.ly/2ZD9307uItG',
    date: '11/12/2016',
  }, {
    avatar: 'https://pbs.twimg.com/profile_images/778923731846590464/jVirtTZ1_bigger.jpg',
    username: 'hqro',
    fullname: 'Guillaume Larivière',
    content: 'Retrouvez-moi aujourd\'hui pour un petit tuto #React sur la plateforme @elephorm',
    date: '11/12/2016',
  }, {
    avatar: 'https://pbs.twimg.com/profile_images/797746572742234112/yaTOzHW8_bigger.jpg',
    username: 'reactjs_fr',
    fullname: 'ReactJS.fr',
    content: 'Petit rappel sur les boucles et les conditions en #JavaScript : http://reactjs.fr/javascript/boucles-conditions/',
    date: '01/11/2016',
  }];
 
  export default tweets